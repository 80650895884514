<template>
  <label
    class="textarea"
    :class="{
      'textarea--filled': !!value,
      'textarea--error': !!error,
      'textarea--icon-left': iconPosition === 'left',
      'textarea--icon-right': iconPosition === 'right',
      'textarea--modified': modified,
    }"
  >
    <span v-if="title" class="textarea__title">
      <span>{{ title }}</span>
      <span class="input__required" v-if="required">*</span>
    </span>
    <span class="textarea__container">
      <button class="textarea__action" type="button" v-if="iconPosition" @click="$emit('action')">
        <slot name="icon">
          <IconComponent name="search-2-line" />
        </slot>
      </button>
      <textarea
        v-mask="mask"
        :placeholder="placeholder"
        class="textarea__field"
        ref="textarea"
        :value="value"
        @input="handleInput"
        @blur="$emit('blur', $event)"
      />
    </span>
    <span v-if="subtitle" class="textarea__subtitle">{{ subtitle }}</span>
    <span v-if="error" class="textarea__error">{{ error }}</span>
  </label>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "TextareaComponent",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    mask: {
      type: String,
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    placeholder: {
      type: [String, Number],
    },
    min: Number,
    max: Number,
    value: {
      type: [String, Number],
    },
    error: {
      type: String,
    },
    iconPosition: {
      type: String,
      validator(value) {
        return value === "left" || value === "right";
      },
    },
    modified: Boolean,
    required: Boolean,
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
  components: { IconComponent },
  directives: {
    mask: VueMaskDirective,
  },
};
</script>

<style lang="stylus">
.textarea {
  display inline-flex
  flex-direction column
  gap 5px

  &__container {
    display flex
    position relative
    background: var(--white);
    border: 2px solid var(--border-color);
    box-sizing: border-box;
    border-radius: var(--main-radius);
    height auto
    transition var(--transition)

    &:hover {
      border-color var(--dark-light)
    }
  }

  &:focus-within {
    & ^[0]__container {
      border-color var(--brown-light)
    }

    & .icon svg path {
      stroke var(--gray-3-dark)
    }
  }

  &--filled:not(:focus-within) {
    & ^[0]__container {
      border-color var(--dark)
    }

    & .icon svg path {
      stroke var(--dark)
    }
  }

  &--error& {
    & ^[0]__container {
      border-color var(--red)
    }

    & .icon svg path {
      stroke var(--red)
    }

    &__field {
      color: var(--red);

      &::placeholder {
        color: var(--red);
      }
    }
  }

  &--focused {
    & ^[0]__container {
      border-color var(--dark)
    }
  }

  &--error {
    & ^[0]__container {
      border-color var(--red)
    }
  }

  &--icon-left &__action {
    absolute left top
  }

  &--icon-left &__field {
    padding 12px 16px 12px 44px
  }

  &--icon-right &__action {
    absolute right top
  }

  &--icon-right &__field {
    padding 12px 44px 12px 16px
  }

  &--modified {
    position relative

    & ^[0]__title {
      absolute top 7px left 15px
      z-index 1
      font-size: 0.85em
      line-height: 20px;
    }

    & ^[0]__field {
      padding 32px 15px 5px
    }
  }

  &__title {
    font-size: 0.75em
    line-height: 13px;
    color: var(--body-color);
    opacity: 0.5;
    white-space pre-line
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    color: var(--gray);
  }

  &__required {
    color var(--red)
  }

  &__error {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    color: var(--red);
  }

  &__action {
    background none
    border none
    height 100%
    display inline-flex
    align-items center
    padding 16px
  }

  &__field {
    border none
    background none
    width 100%
    height 100px
    padding 16px
    outline none
    font-weight: normal;
    font-size: 1em;
    color: var(--body-color);

    &::placeholder {
      font-weight: normal;
      color var(--gray-dark-2)
    }
  }

  .icon {
    width 20px
    height 20px

    svg path {
      transition var(--transition)
      stroke var(--gray)
    }
  }
}
</style>
